import { fromJS, Map } from "immutable";

import { normalize } from "Libs/utils";
import logger from "Libs/logger";

// const LOAD_USERS_START = 'app/users/load_users_start';
const LOAD_USERS_SUCCESS = "app/users/load_users_success";
// const LOAD_USERS_FAILURE = 'app/users/load_users_failure';

const LOAD_USER_START = "app/users/load_user_start";
const LOAD_USER_SUCCESS = "app/users/load_user_success";
const LOAD_USER_FAILURE = "app/users/load_user_failure";

const UPDATE_USER_START = "app/users/update_user_start";
export const UPDATE_USER_SUCCESS = "app/users/update_user_success";
const UPDATE_USER_FAILURE = "app/users/update_user_failure";

export const loadUsersSuccess = users => {
  return async (dispatch, getState) => {
    // Merge the current state of users with the old one.
    let mergedUsers = users;
    let prevUsers = getState().user.get("data");
    let mappedUsers = fromJS(normalize(users));

    if (typeof prevUsers !== "undefined") {
      mergedUsers = prevUsers.merge(mappedUsers);
    }

    dispatch({
      type: LOAD_USERS_SUCCESS,
      payload: mergedUsers
    });
  };
};

export const loadUser = userId => async dispatch => {
  dispatch({ type: LOAD_USER_START });
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const user = await client.getAccount(userId);

    dispatch({ type: LOAD_USER_SUCCESS, payload: user });
  } catch (err) {
    logger(err, {
      action: "user_load",
      userId
    });
    dispatch({ type: LOAD_USER_FAILURE, error: true, payload: err });
  }
};

export const updateMe = data => async dispatch => {
  dispatch({ type: UPDATE_USER_START });
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const oldMe = await client.getAccountInfo();
    const resultFromUpdate = await oldMe.update(data);
    const newMe = oldMe.updateLocal(resultFromUpdate.data[0]);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        me: { ...newMe }
      }
    });
  } catch (err) {
    logger(err, {
      action: "user_load"
    });
    dispatch({ type: UPDATE_USER_FAILURE, error: true, payload: err });
  }
};

export default function userReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_USERS_SUCCESS:
      return state
        .set("loading", false)
        .set("data", fromJS(normalize(action.payload)));
    case LOAD_USER_SUCCESS:
      return state
        .set("loading", false)
        .setIn(["data", action.payload.id], action.payload);
    case UPDATE_USER_FAILURE:
    case LOAD_USER_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    default:
      return state;
  }
}
